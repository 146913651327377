
const htmlEntities: Record<string, string> = {
  nbsp: " ",
  cent: "¢",
  pound: "£",
  yen: "¥",
  euro: "€",
  copy: "©",
  reg: "®",
  lt: "<",
  gt: ">",
  quot: '"',
  amp: "&",
  apos: "'",
};


export function decodeHtml(str: string) {
  if (typeof str !== "undefined" && str !== "")
    return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
      var match;

      if (entityCode in htmlEntities) {
        return htmlEntities[entityCode];
      } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
        return String.fromCharCode(parseInt(match[1], 16));
      } else if ((match = entityCode.match(/^#(\d+)$/))) {
        return String.fromCharCode(~~match[1]);
      } else {
        return entity;
      }
    });
  else return "";
}

export function retrieve_screen_orientation(): string {
  if (window.innerWidth > window.innerHeight) {
    return "l";
  } else {
    return "p";
  }
}

export function getHeaderHeight() {
  return document.getElementById("main-header")?.clientHeight || 0;
}

export function autoplayOptions(autoplay: any, autoplay_duration: any) {
  if (autoplay) {
    return {
      delay: autoplay_duration ? autoplay_duration * 1000 : 0,
      disableOnInteraction: true,
    };
  } else {
    return false;
  }
}

export function useVideoAutoplay(videoWrapper: any, videoPlayer: any) {
  const alreadyIntersected = ref(false);

  const initAutoplayOnScroll = (el: any) => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!alreadyIntersected.value && entries[0].isIntersecting) {
          playVid();
          alreadyIntersected.value = true;
        } else if (alreadyIntersected.value && !entries[0].isIntersecting) {
          pauseVid();
          alreadyIntersected.value = false;
        }
      },
      { threshold: 0.5 }
    );

    observer.observe(el.value);
  };

  const playVid = () => {
    if (videoPlayer.value) {
      videoPlayer.value.play();
    }
  };

  const pauseVid = () => {
    if (videoPlayer.value) {
      videoPlayer.value.pause();
    }
  };

  onMounted(() => {
    if (videoWrapper.value) {
      initAutoplayOnScroll(videoWrapper.value);
    }
  });

  watch(videoWrapper, (newValue) => {
    if (newValue) {
      // Handle any additional logic if needed when htmlVideoPlayer is set
    }
  });

  return { playVid, pauseVid, initAutoplayOnScroll };
}

export function generateCustomSpacingsStyles(blockData: any, isMobile: boolean): Record<string, string> {
  const verticalSpacing: Record<string, string> = {};

  // padding-top
  if (blockData?.top_spacing?.top_spacing_rules_enabled) {
    const topPadding = isMobile && blockData.top_spacing.top_custom_mobile_offsets
      ? blockData.top_spacing.top_mobile
      : blockData.top_spacing.top_desktop;

    const topTypePadding = isMobile && blockData.top_spacing.top_custom_mobile_offsets
      ? blockData.top_spacing.top_mobile_type
      : blockData.top_spacing.top_desktop_type;

    if (topPadding && topTypePadding) {
      verticalSpacing["padding-top"] = `${topPadding + topTypePadding}`;
    }
  }

  // padding-bottom
  if (blockData?.bottom_spacing?.bottom_spacing_rules_enabled) {
    const bottomPadding = isMobile && blockData.bottom_spacing.bottom_custom_mobile_offsets
      ? blockData.bottom_spacing.bottom_mobile
      : blockData.bottom_spacing.bottom_desktop;

    const bottomTypePadding = isMobile && blockData.bottom_spacing.bottom_custom_mobile_offsets
      ? blockData.bottom_spacing.bottom_mobile_type
      : blockData.bottom_spacing.bottom_desktop_type;


    if (bottomPadding && bottomTypePadding) {
      verticalSpacing["padding-bottom"] = `${bottomPadding + bottomTypePadding}`;
    }
  }

  return verticalSpacing;
}

export const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]):not(.ps__thumb-x)';

export const handleFocusOnPopup = <T extends string | number | symbol>(
  parentSelector: HTMLElement,
  popupInfo: Record<string, any> | Record<T, keyof T> | {},
  e: KeyboardEvent,
  PreButtonSelector?: HTMLElement,
  onClose?: () => void
) => {
  const firstFocusableElement = parentSelector.querySelectorAll(focusableElements)[0] as HTMLElement;
  const focusableContent = parentSelector.querySelectorAll(focusableElements);
  const lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement;

  // Handle focus trapping logic here
  if (e.key === 'Tab') {
    if (e.shiftKey) {
      // Shift + Tab
      if (document.activeElement === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement.focus();
      }
    } else {
      // Tab
      if (document.activeElement === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    }
  } else if (e.key === "Escape") {
    if (onClose) {
      onClose();
      PreButtonSelector?.focus();
    }
  }
};

export function generateCta(url: string, label: string | null = null, preset: string | null = null) {
  return {
    link: {
      title: label ? label : '',
      url
    },
    preset: preset !== null ? preset || "INTERNAL" : null
  };
}

export function uniqid() {
  const length = 20;
  let result = "";
  const chars = "0123456789-abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}
