
import { defineStore } from "pinia";

export const pageService = defineStore({
  id: "pageService",
  state: () => {
    return {
      _pageMeta: {},
      _pageFields: {},
      _pageOptions: {},
    };
  },
  getters: {
    pageMeta: (state: { _pageMeta: {} }) => state._pageMeta,
    pageFields: (state: { _pageFields: {} }) => state._pageFields,
    pageOptions: (state: { _pageOptions: {} }) => state._pageOptions,
  },
  actions: {
    setMeta(meta: {}) {
      this._pageMeta = meta;
    },
    setFields(fields: {}) {
      this._pageFields = fields;
    },
    setPageOptions(pageOptions: {}) {
        this._pageOptions = pageOptions;
    },
    updatePageOption(key: string, value: any) {
        this._pageOptions = {
          ...this._pageOptions,
          [key]: value,
        };
    },
    handlePageData(data: { post_data: {}; acf: {blocks: any[], page_options: {}} }) {
      this.setMeta(data?.post_data);

      //data.acf.blocks = data.acf.blocks.concat(this._mockedBlocks as any) as any[];
      this.setFields(data?.acf);
      this.setPageOptions(data?.acf?.page_options || {});
    },
    /*async getPage(id?: number) {
      try {
        // Temporarily fetch a local JSON file
        const response = await fetch(`/homepage-mocked-data.json`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const pageData = await response.json();
        this.handlePageData(pageData);
      } catch (err) {
        console.error(err);
      }
    },*/
    updatePageData(data: { post_data: {}; acf: {blocks: any[], page_options: {}} }) {
      if (!data) return;
      this.handlePageData(data);
    }
  },
});
